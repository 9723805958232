<template>
  <!-- <link href='https://fonts.googleapis.com/css?family=Pacifico' rel='stylesheet'> -->
  <NuxtLink to="/">
    <p
      class="header_sitetitle text-6xl sm:text-8xl pt-6 pb-4 sm:pb-8 drop-shadow-lg"
    >
      {{ useRuntimeConfig().public.SHOP_NAME }}
    </p>
  </NuxtLink>
  <br>
</template>

<style scoped>

.header_sitetitle {
  font-family: 'Pacifico';
  /* -webkit-text-stroke: 1px #fff; */
  text-shadow: 0px 0px 3px oklch(var(--n)), 0px 0px 6px oklch(var(--n)), 0px 0px 10px oklch(var(--n));
  color: oklch(var(--n));
  /* font-weight: bold; */
  /* letter-spacing: -4px; */
  text-align: center;
  /* oklch(var(--b3)),   */

  text-shadow: 
        -2px -3px 0 oklch(var(--b1)),  
        -3px -2px 0 oklch(var(--b1)),  
        2px -3px 0 oklch(var(--b1)),
        3px -2px 0 oklch(var(--b1)),
        -2px 3px 0 oklch(var(--b1)),
        -3px 2px 0 oklch(var(--b1)),
        2px 3px 0 oklch(var(--b1)),
        3px 2px 0 oklch(var(--b1));
}

</style>